import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      about_us_title: "About Us",
      about_us_subtitle:
        "Un equipo cercano, exigente, imaginativo y comprometido.",
      about_us_description_1:
        "Neutr has a team of professionals with extensive experience in the audio visual sector. Each member brings a solid and diverse background that enriches our work and enables us to offer creative and effective solutions to our clients.",
      about_us_description_2:
        "In addition to our experience, we stand out for our great ability to adapt to market changes and the demands of our projects. We know how to evolve with agility and efficiency to stay at the forefront of the audio visual industry, ensuring innovative and high-quality results.",
      about_us_description_3:
        "Here, collaboration and teamwork are fundamental pillars. We work cohesively, enhancing individual strengths to achieve common goals. We are committed, empathetic, and respectful individuals, allowing us to cultivate strong relationships both inside and outside the workplace. We overcome challenges together and leave a mark with every project we undertake.",
      about_us_bottom_description_1:
        "Experience, creativity, and constant evolution.",
      about_us_bottom_description_2:
        "We are proud of our adaptability and our ability to achieve innovative results.",

      contact_title: "Contact",
      contact_subtitle: "LET'S WORK TOGETHER!",
      contact_description_1:
        "We have filmed all around the planet. We come from (and often return to) Andalusia. But we mainly live and work in Madrid.",
      contact_description_2:
        "Many different places, many years working together, many different projects, many unsolvable problems solved. Tell us about your project or problem, and we will try to find a solution.",
      contact_full_text:
        "Do you have any questions or suggestions? Interested in collaborating on exciting projects? Don't hesitate to get in touch with us.",
      contact_short_text: "¿Tienes alguna duda o alguna propuesta?",
      contact_form_name: "Name",
      contact_form_email: "Email",
      contact_form_service: "Service",
      contact_form_message: "Message",
      contact_form_send: "Send",
      contact_form_sending: "Sending..",
      contact_form_sent:
        "Your form has been successfully registered, thank you very much",
      contact_button: "Contact",
      contact_legal_start: "By sending you accept our ",
      contact_legal_notice: "legal notice",
      contact_legal_and: " and our ",
      contact_legal_privacy: "privacy policy",

      credentials_title: "Projects",
      credentials_subtitle: "Filter by type of projects or clients",
      credentials_client_filter: "Clients",
      credentials_client_filter_all: "ALL",
      credentials_client_title: "Client",
      credentials_producer_title: "Producer",
      credentials_agency_title: "Agency",
      credentials_services_title: "Services",
      credentials_references_title: "References",
      credentials_extra_content_title: "Extra Content",
      credentials_view_button: "View",

      footer_legal_title: "Legal",
      footer_legal_legal_notice: "Legal Notice",
      footer_legal_privacy: "Privacy",
      footer_copyright: "Copyright ©2024 NEUTR - All Rights Reserved",

      header_home: "Home",
      header_projects: "Projects",
      header_services: "Services",
      header_about_us: "About Us",
      header_contact: "Contact",
      language_selector_es: "Español",
      language_selector_en: "English",

      home_video_1: "Audiovisual<br/>services",
      home_video_2: "Events",
      home_video_3: "Animation",
      home_video_4: "Advertising",
      home_video_5: "Exhibitions",
      home_video_6: "Museums",
      home_services_title_1:
        "In addition to our specialization in Museums and Advertising, our professionals have over 20 years of experience in producing all types of audio visual content. We can confidently address any needs arising from content creation.",
      home_services_title_2:
        "We listen, conceptualize, create, write, design, plan, produce, direct, edit, animate, and post-produce. We create content for advertising campaigns and spots, for branded content or other digital formats and social media. Also for immersive experiences or audio visual installations in temporary architecture. We work with motion graphics, 2D animation, or 3D animation.",
      home_view_services: "View Services",
      home_view_projects: "View Projects",
      home_project_view_more: "View More",
      home_clients_title: "Clients",
      home_clients_subtitle: "Who we work with",

      services_title: "Services",
      services_audio_visual: "Audiovisual services",
      services_audio_visual_subtitle:
        "We create and produce all kinds of audiovisual content",
      services_audio_visual_description_1:
        "In addition to our specialization in Museums and Advertising, our professionals have more than 20 years of experience in the production of all kinds of audiovisual content. Any need derived from the creation of content we can cover without hesitation.",
      services_audio_visual_description_2:
        "We handle all tasks related to the audio visual sector that exist. And if they don't exist, we can invent them.",

      services_advertising: "Advertising & Marketing",
      services_advertising_subtitle: "Especializados en campañas publicitarias",
      services_advertising_description_1:
        "We have extensive experience in creating and executing impactful and effective advertising campaigns. We develop innovative proposals that connect with the target audience and generate results tailored to our clients' expectations, thanks to a team of creative professionals with proven track records.",
      services_advertising_description_2:
        "From conceptualization to execution, we are committed to conveying our brands' messages in a unique and memorable way, using the latest trends and audio visual tools to achieve the desired success.",

      services_museums: "Museums & Events",
      services_museums_subtitle:
        "Creamos y producimos contenidos complejos e instalaciones para Museos y Eventos",
      services_museums_description_1:
        "We have an extensive experience in the comprehensive management of audio visual installations for museums and exhibitions. We coordinate and execute all processes related to the implementation of audio visual technology in cultural environments, helping to create immersive and memorable experiences for visitors.",
      services_museums_description_2:
        "With a meticulous and creative approach, we transform spaces into interactive stages that enhance the visual narrative and enrich the display of artistic or educational content. We have the specialization that is needed to carry out any museum project with a superior level of excellence and professionalism.",

      services_tag_creatividad: "Creativity",
      services_tag_contenido: "Content",
      services_tag_guion: "Script",
      services_tag_desarrollo_audiovisual: "Audiovisual Development",
      services_tag_preproduccion: "Preproduction",
      services_tag_rodaje_beauty: "Beauty Shooting",
      services_tag_rodaje_b_roll: "B-Roll Shooting",
      services_tag_rodaje_entrevistas: "Interview Shooting",
      services_tag_rodaje_aereo: "Aerial Shooting",
      services_tag_rodajes_espana: "Shootings in Spain",
      services_tag_rodajes_internacionales: "International Shootings",
      services_tag_grabacion_eventos: "Event Recording",
      services_tag_cobertura_audiovisual: "Audiovisual Coverage",
      services_tag_service_produccion: "Production Service",
      services_tag_videos_rrss: "Social Media Videos",
      services_tag_video_presentacion: "Presentation Videos",
      services_tag_videos_corporativos: "Corporate Videos",
      services_tag_video_marca: "Brand Videos",
      services_tag_videoclips: "Videoclips",
      services_tag_making_off: "Making Off",
      services_tag_postproduccion: "Postproduction",
      services_tag_edicion_video: "Video Editing",
      services_tag_vfx: "VFX",
      services_tag_etalonaje: "Color Grading",
      services_tag_edicion_sonido: "Sound Editing",
      services_tag_locuciones: "Voiceovers",
      services_tag_musica: "Music",
      services_tag_infografia: "Infographics",
      services_tag_motion_graphics: "Motion Graphics",
      services_tag_animacion_2d: "2D Animation",
      services_tag_animacion_3d: "3D Animation",
      services_tag_infografias: "Infographics",
      services_tag_ilustraciones: "Illustrations",
      services_tag_campanas_publicidad: "Advertising Campaigns",
      services_tag_campanas_rrss: "Social Media Campaigns",
      services_tag_campanas_multiplataforma: "Multiplatform Campaigns",
      services_tag_campanas_ooh: "OOH Campaigns",
      services_tag_campanas_internacionales: "International Campaigns",
      services_tag_copywriting: "Copywriting",
      services_tag_spots_tv: "TV Spots",
      services_tag_campanas_exteriores: "Outdoor Campaigns",
      services_tag_campanas_cine: "Cinema Campaigns",
      services_tag_cunas_radio: "Radio Spots",
      services_tag_graficos: "Graphics",
      services_tag_fotografia: "Photography",
      services_tag_rodaje_aereo: "Aerial Shooting",
      services_tag_rodaje_submarino: "Underwater Shooting",
      services_tag_fotografia_lonas_mupis: "Photography for Banners and Mupis",
      services_tag_museistica: "Museistics",
      services_tag_diseno_contenidos: "Content Design",
      services_tag_exposiciones: "Exhibitions",
      services_tag_arquitectura_efimera: "Temporary Architecture",
      services_tag_muestras_itinerantes: "Itinerant Exhibitions",
      services_tag_instalaciones_exteriores: "Outdoor Installations",
      services_tag_instalaciones_interiores: "Indoor Installations",
      services_tag_instalaciones_museograficas: "Museographic Installations",
      services_tag_formatos_especiales: "Special Formats",
      services_tag_gran_formato: "Large Format",
      services_tag_escenografia: "Scenography",
      services_tag_modulos_expositores: "Exhibitor Modules",
      services_tag_collage_multipantalla: "Multipanel Collage",
      services_tag_proyecciones: "Projections",
      services_tag_proyecciones_inmersivas: "Immersive Projections",
      services_tag_video_mapping: "Video Mapping",
      services_tag_animaciones_2d: "2D Animations",
      services_tag_animaciones_3d: "3D Animations",
      services_tag_vfx: "VFX",
      services_tag_pantallas_tactiles: "Touch Screens",
      services_tag_pantallas_interactivas: "Interactive Screens",
      services_tag_gestion_material_archivo: "Archive Material Management",
      services_tag_ambientes_sonoros: "Sound Environments",
      services_tag_parallax: "PARALLAX",
      services_tag_piezas_interactivas: "Interactive Pieces",
    },
  },
  es: {
    translation: {
      about_us_title: "Nosotros",
      about_us_subtitle:
        "Un equipo cercano, exigente, imaginativo y comprometido.",
      about_us_description_1:
        "Neutr cuenta con profesionales de amplia experiencia en el sector audiovisual. Cada miembro aporta un currículum sólido y diverso que enriquece nuestra labor y nos permite ofrecer soluciones creativas y efectivas a nuestros clientes.",
      about_us_description_2:
        "Tras casi 20 años de experiencia, destacamos por nuestra gran capacidad de adaptación a los cambios del mercado y a las demandas de nuestros proyectos. Evolucionamos con agilidad y eficacia para mantenernos a la vanguardia de la industria audiovisual, garantizando resultados innovadores y de calidad.",
      about_us_description_3:
        "Aquí la colaboración y el compañerismo son pilares fundamentales. Somos personas comprometidas, empáticas y respetuosas, lo que nos permite cultivar relaciones sólidas tanto dentro como fuera del ámbito laboral. Superamos los desafíos juntos y dejamos huella con cada proyecto que emprendemos.",
      about_us_bottom_description_1:
        "Experiencia, creatividad y evolución constante.",
      about_us_bottom_description_2:
        "Amamos los retos y garantizamos resultados.",

      contact_title: "Contacto",
      contact_subtitle: "TRABAJEMOS JUNTOS",
      contact_description_1:
        "Hemos rodado alrededor de todo el planeta. Venimos (y volvemos a menudo) de Andalucía. Pero vivimos y trabajamos principalmente en Madrid.",
      contact_description_2:
        "Muchos lugares diferentes, muchos años currando juntos, muchos proyectos distintos, muchos problemas irresolubles resueltos. Cuéntanos tu proyecto o tu problema e intentaremos darle solución.",
      contact_full_text:
        "¿Tienes alguna duda o alguna propuesta? ¿Interesado en colaborar en proyectos emocionantes? No dudes en ponerte en contacto con nosotros.",
      contact_short_text: "¿Tienes alguna duda o alguna propuesta?",
      contact_form_name: "Nombre",
      contact_form_email: "Email",
      contact_form_service: "Servicio",
      contact_form_message: "Mensaje",
      contact_form_send: "Enviar",
      contact_form_sending: "Enviando..",
      contact_form_sent:
        "Tu formulario se ha registrado correctamente, muchas gracias",
      contact_button: "Contactar",
      contact_legal_start: "Al enviar aceptas nuestro ",
      contact_legal_notice: "aviso legal",
      contact_legal_and: " y nuestra ",
      contact_legal_privacy: "política de privacidad",

      credentials_title: "Proyectos",
      credentials_subtitle: "Filtrar por tipo de proyectos o clientes",
      credentials_client_filter: "Clientes",
      credentials_client_filter_all: "TODOS",
      credentials_client_title: "Cliente",
      credentials_producer_title: "Productora",
      credentials_agency_title: "Agencia",
      credentials_services_title: "Servicios",
      credentials_references_title: "Referencias",
      credentials_extra_content_title: "Contenido Extra",
      credentials_view_button: "Ver",

      footer_legal_title: "Legal",
      footer_legal_legal_notice: "Aviso Legal",
      footer_legal_privacy: "Privacidad",
      footer_copyright: "Copyright ©2024 NEUTR - Todos los Derechos Reservados",

      header_home: "Inicio",
      header_projects: "Proyectos",
      header_services: "Servicios",
      header_about_us: "Nosotros",
      header_contact: "Contacto",
      language_selector_es: "Español",
      language_selector_en: "English",

      home_video_1: "Servicios<br/>audiovisuales",
      home_video_2: "Eventos",
      home_video_3: "Animación",
      home_video_4: "Publicidad",
      home_video_5: "Exposiciones",
      home_video_6: "Museos",
      home_services_title_1:
        "En Neutr hacemos de forma especializada Publicidad, Museos y Animación, además de otros Servicios AV. Creamos y producimos contenidos AV para agencias, clientes/marcas y proyectos tanto locales como internacionales.",
      home_services_title_2:
        "Escuchamos, conceptualizamos, creamos, escribimos, diseñamos, planificamos, producimos, dirigimos, editamos, animamos y postproducimos. Contenidos para campañas publicitarias y spots. Para branded content o para otros formatos digitales y RRSS. Para experiencias inmersivas o instalaciones audiovisuales en arquitectura efímera. Con motion graphics, con animación 2D o con animación 3D.",
      home_view_services: "Ver Servicios",
      home_view_projects: "Ver Proyectos",
      home_project_view_more: "Ver más",
      home_clients_title: "Clientes",
      home_clients_subtitle: "Con quien trabajamos",

      services_title: "Servicios",
      services_audio_visual: "Servicios Audiovisuales",
      services_audio_visual_subtitle:
        "Creamos y producimos todo tipo de contenido audiovisual",
      services_audio_visual_description_1:
        "Aparte de nuestra especialización en Museos y Publicidad, nuestros profesionales tienen más de 20 años de experiencia en la producción de contenidos audiovisuales de todo tipo. Cualquier necesidad derivada de la creación de contenido podemos abarcarla sin dudar.",
      services_audio_visual_description_2:
        "Nos encargamos de todas las tareas relacionadas con el sector audiovisual que existen. Y si no existen, las podemos inventar. ",

      services_advertising: "Publicidad & Márketing",
      services_advertising_subtitle: "Especializados en campañas publicitarias",
      services_advertising_description_1:
        "Contamos con un amplio bagaje en la creación y ejecución de campañas publicitarias impactantes y efectivas. Desarrollamos propuestas innovadoras que conectan con el público objetivo y generan resultados adaptados a las expectativas de nuestros clientes, gracias a un equipo de profesionales creativos con trayectorias contrastadas.",
      services_advertising_description_2:
        "Desde la conceptualización hasta la ejecución, nos comprometemos a transmitir el mensaje de nuestras marcas de una manera única y memorable, utilizando las últimas tendencias y herramientas audiovisuales para alcanzar el éxito deseado.",

      services_museums: "Museos & Eventos",
      services_museums_subtitle:
        "Creamos y producimos contenidos complejos e instalaciones para Museos y Eventos",
      services_museums_description_1:
        "Tenemos amplia experiencia en la gestión integral de instalaciones audiovisuales para museos y exposiciones. Coordinamos y ejecutamos todos los procesos relacionados con la implementación de tecnología audiovisual en entornos culturales, ayudando a crear experiencias inmersivas y memorables para los visitantes.",
      services_museums_description_2:
        "Con un enfoque meticuloso y creativo, transformamos espacios en escenarios interactivos que potencian la narrativa visual y enriquecen la exhibición de contenidos artísticos o educativos. Contamos con la especialización necesaria para llevar a cabo cualquier proyecto museístico con un nivel superior de excelencia y profesionalismo.",

      services_tag_creatividad: "Creatividad",
      services_tag_contenido: "Contenido",
      services_tag_guion: "Guión",
      services_tag_desarrollo_audiovisual: "Desarrollo Audiovisual",
      services_tag_preproduccion: "Preproducción",
      services_tag_rodaje_beauty: "Rodaje Beauty",
      services_tag_rodaje_b_roll: "Rodaje B-Roll",
      services_tag_rodaje_entrevistas: "Rodaje Entrevistas",
      services_tag_rodaje_aereo: "Rodaje Aéreo",
      services_tag_rodajes_espana: "Rodajes en España",
      services_tag_rodajes_internacionales: "Rodajes Internacionales",
      services_tag_grabacion_eventos: "Grabación Eventos",
      services_tag_cobertura_audiovisual: "Cobertura Audiovisual",
      services_tag_service_produccion: "Service de Producción",
      services_tag_videos_rrss: "Videos RRSS",
      services_tag_video_presentacion: "Video Presentación",
      services_tag_videos_corporativos: "Videos Corporativos",
      services_tag_video_marca: "Vídeo de Marca",
      services_tag_videoclips: "Videoclips",
      services_tag_making_off: "Making Off",
      services_tag_postproduccion: "Postproducción",
      services_tag_edicion_video: "Edición de Vídeo",
      services_tag_vfx: "VFX",
      services_tag_etalonaje: "Etalonaje",
      services_tag_edicion_sonido: "Edición de Sonido",
      services_tag_locuciones: "Locuciones",
      services_tag_musica: "Música",
      services_tag_infografia: "Infografía",
      services_tag_motion_graphics: "Motion Graphics",
      services_tag_animacion_2d: "Animación 2D",
      services_tag_animacion_3d: "Animación 3D",
      services_tag_infografias: "Infografías",
      services_tag_ilustraciones: "Ilustraciones",
      services_tag_campanas_publicidad: "Campañas Publicidad",
      services_tag_campanas_rrss: "Campañas RRSS",
      services_tag_campanas_multiplataforma: "Campañas Multiplataforma",
      services_tag_campanas_ooh: "Campañas OOH",
      services_tag_campanas_internacionales: "Campañas Internacionales",
      services_tag_copywriting: "Copywriting",
      services_tag_spots_tv: "Spots TV",
      services_tag_campanas_exteriores: "Campañas exteriores",
      services_tag_campanas_cine: "Campañas Cine",
      services_tag_cunas_radio: "Cuñas radio",
      services_tag_graficos: "Gráficos",
      services_tag_fotografia: "Fotografía",
      services_tag_rodaje_aereo: "Rodaje Aéreo",
      services_tag_rodaje_submarino: "Rodaje Submarino",
      services_tag_fotografia_lonas_mupis: "Fotografía para lonas y mupis",
      services_tag_museistica: "Museística",
      services_tag_diseno_contenidos: "Diseño Contenidos",
      services_tag_exposiciones: "Exposiciones",
      services_tag_arquitectura_efimera: "Arquitectura Efímera",
      services_tag_muestras_itinerantes: "Muestras itinerantes",
      services_tag_instalaciones_exteriores: "Instalaciones en exteriores",
      services_tag_instalaciones_interiores: "Instalaciones en interiores",
      services_tag_instalaciones_museograficas: "Instalaciones museográficas",
      services_tag_formatos_especiales: "Formatos Especiales",
      services_tag_gran_formato: "Gran Formato",
      services_tag_escenografia: "Escenografía",
      services_tag_modulos_expositores: "Modulos expositores",
      services_tag_collage_multipantalla: "Collage Multipantalla",
      services_tag_proyecciones: "Proyecciones",
      services_tag_proyecciones_inmersivas: "Proyecciones Inmersivas",
      services_tag_video_mapping: "Video Mapping",
      services_tag_animaciones_2d: "Animaciones 2D",
      services_tag_animaciones_3d: "Animaciones 3D",
      services_tag_vfx: "VFX",
      services_tag_pantallas_tactiles: "Pantallas táctiles",
      services_tag_pantallas_interactivas: "Pantallas interactivas",
      services_tag_gestion_material_archivo: "Gestión material de archivo",
      services_tag_ambientes_sonoros: "Ambientes Sonoros",
      services_tag_parallax: "PARALLAX",
      services_tag_piezas_interactivas: "Piezas interactivas",
    },
  },
};

i18n
  .use(Backend) // Load translations from files
  .use(LanguageDetector) // Detect user's language
  .use(initReactI18next) // Pass i18n instance to React
  .init({
    supportedLngs: ["en", "es"],
    fallbackLng: ["en", "es"],
    resources,
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
