import logoAnimated from "../../../assets/images/neutr_animated.gif";
import "./HomeServices.css";
import TextBlock from "../../shared/text-block/TextBlock";
import ColorLine from "../../shared/color-line/ColorLine";
import { t } from "i18next";

const HomeServices = () => {
  const services = [
    {
      title: t("services_audio_visual"),
    },
    {
      title: t("services_advertising"),
    },
    {
      title: t("services_museums"),
    },
  ];

  return (
    <div id="home-services">
      <div className="logo-container">
        <img
          className="logo-animated"
          src={logoAnimated}
          alt="logo-animation"
        />
      </div>
      <div className="service-info container">
        <TextBlock text={t("home_services_title_1")} />
        <br />
        <TextBlock text={t("home_services_title_2")} />
      </div>
      <ColorLine options={services} />
    </div>
  );
};

export default HomeServices;
