import "./LanguageSelector.css";
import { useTranslation } from "react-i18next";
import icon from "../../../assets/images/language-selector.svg";
import { useContext } from "react";
import { AppContext } from "../../../context/context-provider";

const LanguageSelector = () => {
  const { t, i18n } = useTranslation();
  const { changeLanguage } = useContext(AppContext);

  const handleChangeLanguage = (language) => {
    changeLanguage(language);
    window.location.reload();
  };

  const newLanguage = i18n.language === "es" ? "en" : "es";

  return (
    <div
      onClick={() => handleChangeLanguage(newLanguage)}
      className="language-selector"
    >
      <img src={icon} alt={`language_selector_${newLanguage}`} />
      <span>{t(`language_selector_${newLanguage}`)}</span>
    </div>
  );
};

export default LanguageSelector;
