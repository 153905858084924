import { useContext, useEffect, useRef, useState } from "react";
import "./Credential.css";
import CredentialDetails from "../credential-details/CredentialDetails";
import Button from "../../shared/button/Button";
import { AppContext } from "../../../context/context-provider";
import { t } from "i18next";

const Credential = ({
  credential,
  someCredentialSelected,
  isSelected,
  onCredentialSelected,
}) => {
  const elementRef = useRef(null);

  const handleClick = () => {
    if (isSelected) return;
    if (onCredentialSelected === undefined) return;
    onCredentialSelected(credential);

    setTimeout(() => {
      window.scrollTo(0, elementRef.current.offsetTop - 88);
    }, 10);
  };

  const handleDeselect = () => {
    onCredentialSelected(null);
    setTimeout(() => {
      window.scrollTo(0, elementRef.current.offsetTop - 88);
    }, 10);
  };

  return (
    <>
      <div
        className={`vertical-credential${isSelected ? " selected" : ""}${
          !isSelected && someCredentialSelected ? " hidden" : ""
        }`}
        ref={elementRef}
      >
        {!isSelected && (
          <CredentialHoverDetails
            credential={credential}
            clientId={credential.clientId}
            onClick={handleClick}
          />
        )}
        {!isSelected && <ImageBlock credential={credential} />}
        {isSelected && (
          <CredentialDetails
            credential={credential}
            clientId={credential.clientId}
            onDeselect={handleDeselect}
          />
        )}
      </div>
    </>
  );
};

const CredentialHoverDetails = ({ credential, clientId, onClick }) => {
  const { getFullClient } = useContext(AppContext);
  const client = getFullClient(clientId);
  return (
    <div className="details">
      <p className="credential-name">{credential.name}</p>
      <p className="credential-client">{client?.name || ""}</p>
      <Button
        text={t("credentials_view_button")}
        isSmall={true}
        isInverted={true}
        onClick={onClick}
      />
    </div>
  );
};

const ImageBlock = ({ credential }) => {
  const { getFullMedia } = useContext(AppContext);
  const [horizontalImage, setHorizontalImage] = useState(null);

  useEffect(() => {
    if (credential.horizontalImage) {
      setHorizontalImage(getFullMedia(credential.horizontalImage));
    }
  }, [credential]);

  return (
    <div
      className="image-block"
      style={{
        backgroundImage: `url(${horizontalImage ? horizontalImage.url : null})`,
      }}
    >
      <div className="horizontal-overlay"></div>
    </div>
  );
};

export default Credential;
