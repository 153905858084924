import ReactPlayer from "react-player";
import "./HomeVideo.css";
import { useEffect, useState } from "react";
import { t } from "i18next";

const HomeVideo = () => {
  const [index, setIndex] = useState(0);
  const [isVisble, setIsVisible] = useState(false);
  const texts = [
    t("home_video_1"),
    t("home_video_2"),
    t("home_video_3"),
    t("home_video_4"),
    t("home_video_5"),
    t("home_video_6"),
  ];

  useEffect(() => {
    setIsVisible(false);
    setTimeout(() => {
      setIsVisible(true);
      setIndex(0);
    }, 500);
  }, []);

  const handleProgress = ({ playedSeconds }) => {
    setIsVisible(false);
    setTimeout(() => {
      setIsVisible(true);
      setIndex((index + 1) % texts.length);
    }, 500);
  };

  return (
    <div>
      <div id="home-player-wrapper">
        <div id="home-video-text">
          <h1
            style={{ whiteSpace: "pre-line" }}
            className={isVisble ? "visible" : "invisible"}
          >
            {texts[index].split("<br/>").join("\n")}
          </h1>
        </div>

        <div className="player-wrapper">
          <ReactPlayer
            className="react-player fixed-bottom"
            url="https://firebasestorage.googleapis.com/v0/b/neutr-admin.appspot.com/o/home.mp4?alt=media&token=9321da5f-da3c-4271-b0c9-2679421e7afa"
            width="100%"
            height="100%"
            playing={true}
            loop={true}
            muted={true}
            pip={false}
            controls={false}
            progressInterval={3000}
            onProgress={handleProgress}
          />
        </div>
      </div>
    </div>
  );
};

export default HomeVideo;
