const services = [
  {
    title: "services_audio_visual",
    subtitle: "services_audio_visual_subtitle",
    descriptions: [
      "services_audio_visual_description_1",
      "services_audio_visual_description_2",
    ],
    video: {
      url: "https://firebasestorage.googleapis.com/v0/b/neutr-admin.appspot.com/o/services_audiovisual_services.mp4?alt=media&token=8b16cf80-796b-44e3-aaf5-15621db9b73f",
    },
    tags: [
      "services_tag_creatividad",
      "services_tag_contenido",
      "services_tag_guion",
      "services_tag_desarrollo_audiovisual",
      "services_tag_preproduccion",
      "services_tag_rodaje_beauty",
      "services_tag_rodaje_b_roll",
      "services_tag_rodaje_entrevistas",
      "services_tag_rodaje_aereo",
      "services_tag_rodajes_espana",
      "services_tag_rodajes_internacionales",
      "services_tag_grabacion_eventos",
      "services_tag_cobertura_audiovisual",
      "services_tag_service_produccion",
      "services_tag_videos_rrss",
      "services_tag_video_presentacion",
      "services_tag_videos_corporativos",
      "services_tag_video_marca",
      "services_tag_videoclips",
      "services_tag_making_off",
      "services_tag_postproduccion",
      "services_tag_edicion_video",
      "services_tag_vfx",
      "services_tag_etalonaje",
      "services_tag_edicion_sonido",
      "services_tag_locuciones",
      "services_tag_musica",
      "services_tag_infografia",
      "services_tag_motion_graphics",
      "services_tag_animacion_2d",
      "services_tag_animacion_3d",
      "services_tag_locuciones",
      "services_tag_infografias",
      "services_tag_ilustraciones",
    ],
  },
  {
    title: "services_advertising",
    subtitle: "services_advertising_subtitle",
    descriptions: [
      "services_advertising_description_1",
      "services_advertising_description_2",
    ],
    video: {
      url: "https://firebasestorage.googleapis.com/v0/b/neutr-admin.appspot.com/o/services_advertising_marketing.mp4?alt=media&token=e969e622-16aa-4afe-832d-fcb3aacd1ed2",
    },
    tags: [
      "services_tag_campanas_publicidad",
      "services_tag_campanas_rrss",
      "services_tag_campanas_multiplataforma",
      "services_tag_campanas_ooh",
      "services_tag_campanas_internacionales",
      "services_tag_copywriting",
      "services_tag_spots_tv",
      "services_tag_campanas_exteriores",
      "services_tag_campanas_cine",
      "services_tag_cunas_radio",
      "services_tag_graficos",
      "services_tag_fotografia",
      "services_tag_rodaje_aereo",
      "services_tag_rodaje_submarino",
      "services_tag_fotografia_lonas_mupis",
    ],
  },
  {
    title: "services_museums",
    subtitle: "services_museums_subtitle",
    descriptions: [
      "services_museums_description_1",
      "services_museums_description_2",
    ],
    video: {
      url: "https://firebasestorage.googleapis.com/v0/b/neutr-admin.appspot.com/o/services_museum_events.mov?alt=media&token=95f43477-0904-4591-ada9-9fd0140e2803",
    },
    tags: [
      "services_tag_museistica",
      "services_tag_diseno_contenidos",
      "services_tag_exposiciones",
      "services_tag_arquitectura_efimera",
      "services_tag_muestras_itinerantes",
      "services_tag_instalaciones_exteriores",
      "services_tag_instalaciones_interiores",
      "services_tag_instalaciones_museograficas",
      "services_tag_formatos_especiales",
      "services_tag_gran_formato",
      "services_tag_escenografia",
      "services_tag_modulos_expositores",
      "services_tag_collage_multipantalla",
      "services_tag_proyecciones",
      "services_tag_proyecciones_inmersivas",
      "services_tag_video_mapping",
      "services_tag_animaciones_2d",
      "services_tag_animaciones_3d",
      "services_tag_vfx",
      "services_tag_pantallas_tactiles",
      "services_tag_pantallas_interactivas",
      "services_tag_gestion_material_archivo",
      "services_tag_ambientes_sonoros",
      "services_tag_parallax",
      "services_tag_piezas_interactivas",
    ],
  },
];

export { services };
