import "./Footer.css";
import logo from "../../../assets/images/logo-footer.svg";
import logoAnimated from "../../../assets/images/footer_alt_logo.gif";
import logo_instagram from "../../../assets/images/logo_instagram.svg";
import logo_vimeo from "../../../assets/images/logo_vimeo.png";
import logo_linkedin from "../../../assets/images/logo_linkedin.svg";
import ContactForm from "../contact-form/ContactForm";
import HorizontalDivider from "../horizontal-divider/HorizontalDivider";
import { useContext } from "react";
import { AppContext } from "../../../context/context-provider";
import { t } from "i18next";

const urlVimeo = "https://vimeo.com/somosneutr";
const urlLinkedIn = "https://www.linkedin.com/company/neutr/";
const urlInstagram = "https://www.instagram.com/somosneutr/";

const Footer = () => {
  const { displayFooter } = useContext(AppContext);
  return (
    <footer id="footer">
      {displayFooter && <FooterInfo />}
      {!displayFooter && <FooterInfoAlt />}
      <FooterCoypright />
    </footer>
  );
};

const FooterInfo = () => {
  return (
    <div id="footer_info">
      <FooterContact />
      <div className="empty" />
      <FooterLegal />
      <FooterDetails />
    </div>
  );
};

const FooterContact = () => {
  const handleClick = () => {
    window.location.href = "/contact";
  };
  return (
    <div id="footer_contact">
      <ContactForm inverted={true} onlyButton={true} onClick={handleClick} />
    </div>
  );
};

const FooterLegal = () => {
  return (
    <div id="footer_legal">
      <h4 className="legal_title">{t("footer_legal_title")}</h4>
      <div className="links">
        <a href="/legal">{t("footer_legal_legal_notice")}</a>
        <HorizontalDivider inverted={true} className="mobile-divider" />
        <a href="/privacy">{t("footer_legal_privacy")}</a>
      </div>
    </div>
  );
};

const FooterDetails = () => {
  return (
    <div id="footer_details">
      <img className="logo" src={logo} alt="logo" />
      <div className="footer_details_social">
        <FooterSocialLink src={logo_vimeo} link={urlVimeo} text="Vimeo" />
        <FooterSocialLink
          src={logo_instagram}
          link={urlInstagram}
          text="Instagram"
        />

        <FooterSocialLink
          src={logo_linkedin}
          link={urlLinkedIn}
          text="LinkedIn"
        />
      </div>
    </div>
  );
};

const FooterInfoAlt = () => {
  return (
    <div id="footer_info" className="footer_alt">
      <FooterDetailsAlt />
    </div>
  );
};

const FooterDetailsAlt = () => {
  return (
    <div id="footer_details">
      <img className="logo" src={logoAnimated} alt="logo" />
      <div className="footer_details_social">
        <FooterSocialLink src={logo_vimeo} link={urlVimeo} text="Vimeo" />
        <FooterSocialLink
          src={logo_instagram}
          link={urlInstagram}
          text="Instagram"
        />

        <FooterSocialLink
          src={logo_linkedin}
          link={urlLinkedIn}
          text="LinkedIn"
        />
      </div>
    </div>
  );
};

const FooterSocialLink = ({ src, link, text }) => {
  return (
    <a target="_blank" href={link}>
      <img src={src} alt={text} />
    </a>
  );
};

const FooterCoypright = () => {
  return (
    <div id="footer_copyright">
      <p>{t("footer_copyright")}</p>
    </div>
  );
};

export default Footer;
