import React, { useEffect, useReducer } from "react";
import { services } from "./context-data";
import { getAllData } from "./firebase-repository";
import { preloadImages, usePreloadImages } from "./context-preload-images";
import { useTranslation } from "react-i18next";

const defaultContext = {
  allReady: false,
  activeLanguage: "es",
  blockScroll: false,
  clients: [],
  credentials: [],
  displayFooter: true,
  medias: [],
  homeCredentials: [],
  displayFloatingId: null,
  displayFloatinMenu: false,
  services: services,
};

const AppContext = React.createContext(defaultContext);

const getHomeCredentials = (allCredentiales) => {
  let baseHome = allCredentiales.filter(
    (credential) => credential.displayInHome === true
  );
  baseHome.sort((a, b) => a.priority - b.priority);
  return baseHome;
};

const prepareCredentials = (allCredentiales, activeLanguage) => {
  return allCredentiales.map((credential) => {
    let multiCredential = {
      ...credential,
      spanishName: credential.name,
      spanishProjectDescription: credential.projectDescription,
      spanishServiceDescription: credential.serviceDescription,
      spanishMainVideo: credential.mainVideo,
      englishName:
        credential.englishName != null && credential.englishName != ""
          ? credential.englishName
          : credential.name,
      englishProjectDescription:
        credential.englishProjectDescription != null &&
        credential.englishProjectDescription != ""
          ? credential.englishProjectDescription
          : credential.projectDescription,
      englishServiceDescription:
        credential.englishServiceDescription != null &&
        credential.englishServiceDescription != ""
          ? credential.englishServiceDescription
          : credential.serviceDescription,
      englishMainVideo:
        credential.englishMainVideo != null && credential.englishMainVideo != ""
          ? credential.englishMainVideo
          : credential.mainVideo,
    };

    if (multiCredential.references != null) {
      multiCredential.references.forEach((reference) => {
        if (activeLanguage === "en") {
          reference.name = reference.englishName;
        }
      });
    }

    return {
      ...multiCredential,
      name:
        activeLanguage === "es"
          ? multiCredential.spanishName
          : multiCredential.englishName,
      projectDescription:
        activeLanguage === "es"
          ? multiCredential.spanishProjectDescription
          : multiCredential.englishProjectDescription,
      serviceDescription:
        activeLanguage === "es"
          ? multiCredential.spanishServiceDescription
          : multiCredential.englishServiceDescription,
      mainVideo:
        activeLanguage === "es"
          ? multiCredential.spanishMainVideo
          : multiCredential.englishMainVideo,
    };

    //credential
  });
};

const reducer = (state, action) => {
  switch (action.type) {
    case "onDataUpdated": {
      const preparedCredentials = prepareCredentials(
        action.payload.credentials,
        state.activeLanguage
      );
      return {
        ...state,
        allReady: true,
        clients: action.payload.clients,
        credentials: preparedCredentials,
        medias: action.payload.medias,
        homeCredentials: getHomeCredentials(preparedCredentials),
      };
    }
    case "onCredentialsUpdated": {
      return {
        ...state,
        credentials: action.payload,
        homeCredentials: getHomeCredentials(action.payload),
      };
    }
    case "onClientsUpdated": {
      return {
        ...state,
        clients: action.payload,
      };
    }
    case "onMediasUpdated": {
      return {
        ...state,
        medias: action.payload,
      };
    }
    case "shouldDisplayFloatingId":
      return {
        ...state,
        displayFloatingId: action.payload,
      };
    case "shouldDisplayFloatingMenu":
      return {
        ...state,
        displayFloatinMenu: action.payload,
      };
    case "shouldDisplayFooter":
      return {
        ...state,
        displayFooter: action.payload,
      };
    case "shouldBlockScroll":
      return {
        ...state,
        blockScroll: action.payload,
      };
    case "setLanguage":
      return {
        ...state,
        activeLanguage: action.payload,
      };
    default:
      return state;
  }
};
const AppContextProvider = ({ children }) => {
  const { i18n } = useTranslation();
  const [state, dispatch] = useReducer(reducer, defaultContext);

  useEffect(() => {
    dispatch({ type: "setLanguage", payload: i18n.language });

    if (!state.allReady) {
      const syncAll = async () => {
        let data = await getAllData();
        if (data != null) {
          dispatch({ type: "onDataUpdated", payload: data });
          preloadImages(data);
        }
      };

      syncAll();
    }
    //allReady
    //syncMedias();
    //syncCredentials();
    //syncClients();
  }, []);

  /*
  const usePreloadImages = (data) => {
    useEffect(() => {
      preload
    }, [data]);
  };
  */
  const shouldDisplayFloatingId = (displayFloatingId) => {
    dispatch({ type: "shouldDisplayFloatingId", payload: displayFloatingId });
  };

  const shouldDisplayFloatingMenu = (displayFloating) => {
    dispatch({ type: "shouldDisplayFloatingMenu", payload: displayFloating });
  };
  const shouldBlockScroll = (blockScroll) => {
    dispatch({ type: "shouldBlockScroll", payload: blockScroll });
  };
  const getFullClient = (clientId) => {
    let client = state.clients.find((client) => client.id === clientId);
    if (client != null) {
      client.image = getFullMedia(client.imageId);
    }
    return client;
  };
  const getFullMedia = (mediaId) => {
    return state.medias.find((media) => media.id === mediaId);
  };
  const shouldDisplayFooter = (displayFooter) => {
    dispatch({ type: "shouldDisplayFooter", payload: displayFooter });
  };
  const changeLanguage = (language) => {
    console.log("changing language to", language);
    i18n.changeLanguage(language);
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        changeLanguage,
        shouldBlockScroll,
        shouldDisplayFloatingId,
        shouldDisplayFloatingMenu,
        shouldDisplayFooter,
        getFullClient,
        getFullMedia,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
