import "./ContactForm.css";
import { useNavigate } from "react-router-dom";

import Button from "../../shared/button/Button";
import { useRef, useState } from "react";
import { saveForm } from "../../../context/firebase-repository";
import { t } from "i18next";

const ContactForm = ({ className, inverted, onlyButton, onClick }) => {
  if (onlyButton) {
    return OnlyButtonForm({ className, inverted, onClick });
  }
  return FullForm({ className, inverted });
};

const OnlyButtonForm = ({ className, inverted, onClick }) => {
  return (
    <div
      className={`contact-form contact-form-only-button ${className} ${
        inverted ? "inverted" : ""
      }`}
    >
      <div className="contact-left">
        <p className="contact-info only-button only-no-mobile">
          {t("contact_full_text")}
        </p>
        <p className="contact-info only-button only-mobile">
          {t("contact_short_text")}
        </p>
      </div>

      <div className="contact-right">
        <Button
          className="form-button"
          text={t("contact_button")}
          isInverted={inverted}
          fillBackground={true}
          onClick={onClick}
        />
      </div>
    </div>
  );
};

const FullForm = ({ className, inverted }) => {
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const formRef = useRef(null);

  const handleClick = async () => {
    if (submitting) return;
    let form = formRef.current;

    const name = form.elements["name"].value;
    const email = form.elements["email"].value;
    const service = form.elements["service"].value;
    const message = form.elements["message"].value;

    setSubmitting(true);

    // Send the form data to the server
    let response = await saveForm({ name, email, service, message });
    setSubmitting(false);
    if (response.status === "success") {
      setSubmitted(true);
    }
  };

  return (
    <form
      className={`contact-form ${className} ${inverted ? "inverted" : ""}`}
      ref={formRef}
    >
      <h4 className="contact-title"> {t("contact_title")} </h4>

      {!submitted && (
        <>
          <p className="contact-info">{t("contact_full_text")}</p>

          <div className="contact-block">
            <input
              type="text"
              id="form-name"
              name="name"
              placeholder={t("contact_form_name")}
              required
            />
            <input
              type="text"
              id="form-email"
              name="email"
              placeholder={t("contact_form_email")}
              required
            />
            <input
              type="text"
              id="form-service"
              name="service"
              placeholder={t("contact_form_service")}
              required
            />
          </div>
          <div className="contact-block">
            <input
              type="text"
              id="form-message"
              name="message"
              placeholder={t("contact_form_message")}
              required
            />
            <Button
              text={
                submitting ? t("contact_form_sending") : t("contact_form_send")
              }
              isInverted={inverted}
              fillBackground={true}
              onClick={handleClick}
            />
          </div>
          <ContactLegal />
        </>
      )}
      <div
        className={`contact-form-confirmation ${submitted ? "submitted" : ""}`}
      >
        <p>{t("contact_form_sent")}</p>
      </div>
    </form>
  );
};

const ContactLegal = () => {
  return (
    <div>
      <p className="form-legal">
        {t("contact_legal_start")}
        <a href="/legal" target="_blank">
          {t("contact_legal_notice")}
        </a>{" "}
        {t("contact_legal_and")}
        <a href="/privacy" target="_blank">
          {t("contact_legal_privacy")}
        </a>
      </p>
    </div>
  );
};
export default ContactForm;
