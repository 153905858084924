import { useContext, useEffect, useRef, useState } from "react";
import "./HomeCredentials.css";
import Button from "../../shared/button/Button";
import { AppContext } from "../../../context/context-provider";
import { t } from "i18next";

const HomeCredentials = ({ credentials, onCredentialClick }) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollContainerRef = useRef(null);

  const handleScrollLeft = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const newScrollLeft = Math.max(0, scrollLeft - 100);
      setScrollLeft(newScrollLeft);
      container.scrollLeft -= container.clientWidth;
    }
  };

  const handleScrollRight = () => {
    const container = scrollContainerRef.current;
    if (container) {
      const newScrollLeft = Math.min(
        container.scrollWidth - container.clientWidth,
        scrollLeft + 100
      );
      setScrollLeft(newScrollLeft);
      container.scrollLeft += container.clientWidth;
    }
  };

  return (
    <div id="home-credentials-container">
      <div id="home-credential-scroll-left" onClick={handleScrollLeft}>
        <img
          className="scroll-image"
          src="/assets/images/collapse.svg"
          alt="Collapse"
        />
      </div>
      <div id="home-credential-scroll-right" onClick={handleScrollRight}>
        <img
          className="scroll-image"
          src="/assets/images/collapse.svg"
          alt="Collapse"
        />
      </div>

      <div id="home-credentials-scrollable" ref={scrollContainerRef}>
        {credentials.map((credential, index) => (
          <HomeCredential
            key={index}
            credential={credential}
            onCredentialClick={onCredentialClick}
          />
        ))}
      </div>
    </div>
  );
};

const HomeCredential = ({ credential, onCredentialClick }) => {
  const { getFullMedia } = useContext(AppContext);
  const [image, setImage] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  let delay = null;

  useEffect(() => {
    if (credential.verticalImage != null) {
      setImage(getFullMedia(credential.verticalImage));
    }
  });

  const handleMouseEnter = () => {
    setIsHovered(true);
    delay = setTimeout(() => {
      setIsDetailsVisible(true);
    }, 400);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    clearTimeout(delay);
    setIsDetailsVisible(false);
  };

  return (
    <div
      className={`home-credential ${isHovered ? "hovered" : ""}`}
      style={{
        backgroundImage: `url(${image ? image.url : null})`,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`overlay ${isHovered && isDetailsVisible ? "visible" : ""}`}
      ></div>
      <div
        className={`credential-details ${
          isHovered && isDetailsVisible ? "visible" : ""
        }`}
      >
        <p className="credential-name">{credential.name}</p>
        <p className="credential-client">
          {credential != null && credential.client != null
            ? credential.client.name
            : ""}
        </p>
        <Button
          text={t("home_project_view_more")}
          isSmall={true}
          isInverted={true}
          onClick={() => onCredentialClick(credential)}
        />
      </div>
    </div>
  );
};

export default HomeCredentials;
