import { useContext } from "react";
import { AppContext } from "../../../context/context-provider";
import SectionTitle from "../../shared/section-title/SectionTitle";
import "./HomeClients.css";
import { t } from "i18next";

const HomeClients = ({ clients }) => {
  const tempClients = clients;
  return (
    <div>
      <SectionTitle
        title={t("home_clients_title")}
        subtitle={t("home_clients_subtitle")}
      />
      <div className="clients-container">
        <div className="clients-list clients-list-primary">
          {tempClients.map((client, index) => (
            <Client key={index} client={client} />
          ))}
          {tempClients.map((client, index) => (
            <Client key={index} client={client} />
          ))}
        </div>
      </div>
    </div>
  );
};

const Client = ({ client }) => {
  const { getFullMedia } = useContext(AppContext);
  let image = getFullMedia(client.imageId);
  return (
    <div className="client">
      <img src={image.url} alt={client.name} />
    </div>
  );
};

export default HomeClients;
