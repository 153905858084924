import "./AboutUsPage.css";

import logoIcon from "../../assets/images/logo-icon-white.svg";
import SectionTitle from "../../components/shared/section-title/SectionTitle";
import TextBlockColumn from "../../components/shared/text-block-column/TextBlockColumn";
import ReactPlayer from "react-player";
import { AppContext } from "../../context/context-provider";
import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { t } from "i18next";

const AboutUsPage = () => {
  const { shouldDisplayFooter } = useContext(AppContext);
  const location = useLocation(); // Get the current route location
  useEffect(() => {
    shouldDisplayFooter(true);
  }, [location.pathname]);

  return (
    <div className="page page-top-margin" id="about-us-page">
      <SectionTitle
        subtitle={t("about_us_title")}
        title={t("about_us_subtitle")}
      />

      <div className="container">
        <TextBlockColumn
          title=""
          blocks={[
            {
              text: t("about_us_description_1"),
              flex: 1,
            },
            {
              text: t("about_us_description_2"),
              flex: 1,
            },
            {
              text: t("about_us_description_3"),
              flex: 1,
            },
          ]}
        />
      </div>

      <AboutUsBottom />
    </div>
  );
};

const AboutUsBottom = () => {
  /*<p className="about-title">LEGACY</p>*/
  return (
    <div id="about-us-video-block">
      <div className="about-bg">
        <AboutUsVideo />
      </div>

      <div className="text block">
        <p className="about-content">
          {t("about_us_bottom_description_1")}
          <br />
          {t("about_us_bottom_description_2")}
        </p>

        <img className="about-logo" src={logoIcon} alt="logo" />
      </div>
    </div>
  );
};

const AboutUsVideo = () => {
  return (
    <ReactPlayer
      className="react-player fixed-bottom"
      url="https://firebasestorage.googleapis.com/v0/b/neutr-admin.appspot.com/o/Neutr_Web_Equipo_v02_240417.mp4?alt=media&token=c107be3d-9463-4e57-8746-1f1ef61e6099"
      width="100%"
      height="100%"
      playing={true}
      loop={true}
      muted={true}
      pip={false}
      controls={false}
    />
  );
};

export default AboutUsPage;
